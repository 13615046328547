.form {
    background: #fafafa;
    border: 1px solid #ddd;
    padding: 1.5rem;
    border-radius: 6px;
  }
  
  /* Form groups for each field */
  .formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .label {
    font-weight: 500;
    margin-bottom: 0.3rem;
  }
  .input {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  /* Gray highlight on input focus */
  .input:focus {
    border-color: #888;
    outline: none;
  }
  
  /* Cancel button styling */
  .cancelButton {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: 600;
    background: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .cancelButton:disabled {
    background: #777;
    cursor: not-allowed;
  }
  
  /* Feedback message styles */
  .successMessage, .errorMessage {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 500;
  }
  .successMessage {
    color: #2e7d32;  /* green-ish */
  }
  .errorMessage {
    color: #d32f2f;  /* red-ish */
  }
  