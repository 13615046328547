.appContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  /* Header with site title and login button */
  .header {
    padding: 1rem 2rem;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    font-size: 1.5rem;
    margin: 0;
  }
  .loginButton {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    background: #f5f5f5;
    color: #000;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
  .loginButton:hover {
    background: #e9e9e9;
  }
  
  /* Main content area */
  .mainSection {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2rem;
    justify-content: center;
    align-items: flex-start;
    background: #fff;
  }
  .formSection, .upcomingSection {
    flex: 1 1 300px;
    margin: 1rem;
    max-width: 400px;
  }
  .sectionTitle {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .placeholderText {
    font-style: italic;
    color: #777;
  }
  
  /* Responsive: stack sections vertically on narrow screens */
  @media (max-width: 600px) {
    .mainSection {
      flex-direction: column;
      align-items: stretch;
    }
    .formSection, .upcomingSection {
      max-width: 100%;
      margin: 1rem 0;
    }
  }
  