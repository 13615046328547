/* Container for the form – white background, grey border, centered */
.form {
    background: #FFFFFF;           /* White background for a clean look */
    color: #333333;               /* Soft black text (dark grey) */
    padding: 20px;
    border: 1px solid #CCCCCC;    /* Light grey border */
    border-radius: 5px;
    max-width: 400px;             /* Make form container reasonably width */
    margin: 0 auto;               /* Center horizontally */
  }
  
  /* Each label-input group spacing */
  .formGroup {
    margin-bottom: 1rem;
  }
  
  /* Label styling */
  .label {
    display: block;
    margin-bottom: 0.3em;
    font-weight: normal;
    color: #333333;
  }
  
  /* Inputs and select dropdown styling */
  .input {
    width: 100%;
    padding: 8px 10px;
    font-size: 1rem;
    color: #333333;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  /* Change border color on focus to a grey highlight */
  .input:focus {
    outline: none;
    border-color: #888888;  /* darker grey on focus */
  }
  
  /* Submit button styling */
  .button {
    background-color: #333333;  /* Dark grey (almost black) background */
    color: #FFFFFF;
    font-size: 1rem;
    padding: 10px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Hover state for button */
  .button:hover:not(:disabled) {
    background-color: #000000;  /* On hover, a bit darker (black) */
  }
  
  /* Disabled state for button */
  .button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  /* Error message styling */
  .errorMessage {
    color: #CC0000;             /* Red text for errors */
    background: transparent;
    margin-bottom: 1rem;
    font-weight: bold;
  }
  
  /* Success message styling */
  .successMessage {
    color: #228B22;             /* Green text (ForestGreen) for success */
    font-weight: bold;
    text-align: center;
  }
  